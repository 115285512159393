<template>
  <el-container class="main">
    <left-slide :collapse="collapse"></left-slide>
    <el-container class="rightContent">
      <el-header class="el-header">
        <v-header @changeCollapse="changeCollapse"></v-header>
      </el-header>
      <el-scrollbar class="scrollbar">
        <el-main style="height: 100%">
          <cTransition> </cTransition>
        </el-main>
      </el-scrollbar>
    </el-container>
  </el-container>
</template>
<script>
import cTransition from "@/components/animate";
import leftSlide from "./leftSlide";
import header from "./header";
// import timeShow from "@/components/header/timeShow";
// import breadcrumb from "@/components/header/breadcrumb";
export default {
  name: "index",
  data() {
    return {
      collapse: false,
    };
  },
  methods: {
    changeCollapse() {
      this.collapse = !this.collapse;
    },
  },
  components: {
    leftSlide,
    // timeShow,
    // breadcrumb,
    'v-header':header,
    cTransition,
  },
  computed: {
    page() {
      var router = this.$route;
      return router.meta.title;
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  .rightContent{
      flex:1;
      display: flex;
      flex-direction: column;
      .el-header{
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
            background: #fff;
      }
      .scrollbar{
          flex:1;
          /deep/.el-scrollbar__wrap {
          overflow-x: hidden;
          background: #E9EEF3
      }
      }
      
  }
}
</style>
