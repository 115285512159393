<template>
    <div class="breadcrumb clearfix mb-2">
        <!-- <div class="float-left">
            {{$t($route.meta.title)}}
        </div> -->
        <el-breadcrumb separator="/" class="float-right">
            <el-breadcrumb-item
                v-for="(item, index) in breadList"
                :to="{
                    path:item.path,
                    query:item.query
                }"
                :replace="true"
                :key="index"
            >{{$t(item.meta.title)}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
export default {
    data() {
        return {
            breadList: [],
        };
    },
    watch: {
        $route: {
            handler(route) {
                let allList = route.matched.filter((item) => {
                    if (item.meta && item.meta.title) {
                        if (item.name == "home") {
                            item.path = "/home";
                        }
                        if(route.params.subjectId){
                            item.path = item.path.replace(/(?<=subject\/).*?(?=\/chapter)/i,route.params.subjectId)
                        }
                        if(route.params.chapterId){
                            item.path = item.path.replace(/(?<=chapter\/).*?(?=\/section)/i,route.params.chapterId)
                        }
                        if(route.params.examinationId){
                            item.path = item.path.replace(/(?<=section\/).*?(?=\/examination)/i,route.params.examinationId)
                        }
                        // if(item.path.indexOf(':subjectId')){
                        //     item.path = item.path.replaceAll(':subjectId',route.params.subjectId)
                        // }
                        // if(item.path.indexOf(':chapterId')){
                        //     item.path = item.path.replaceAll(':chapterId',route.params.chapterId)
                        // }
                        // if(item.path.indexOf(':sectionId')){
                        //     item.path = item.path.replaceAll(':sectionId',route.params.sectionId)
                        // }
                        // item.query = item.meta.query
                        // else {
                        //     item.path = "";
                        // }
                        return true;
                    }
                });
                // if (allList[0].path !== "/home") {
                //     allList.unshift({
                //         path: "/home",
                //         meta: { title: "tIndex" },
                //     });
                // }
                this.breadList = allList;
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
    margin-bottom: 20px;
}

.float-left{float:left;}
.float-right{float:right;}
.clearfix:after{content:'';display:block; clear:both; text-indent:-99999px;}

.mb-2 {margin-bottom:20px;}
</style>
