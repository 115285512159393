<template>
  <div class="flexBC header">
    <div class="flex header_left">
      <el-button @click="changeCollapse">
        <i class="el-icon-s-operation fs20"></i>
      </el-button>
    </div>
    <breadcrumb />
    <div class="flex header_right">
      <div>
        <!-- <router-link to="/notification">
          <el-button>
            <i class="el-icon-message-solid fs20"></i>
          </el-button>
        </router-link> -->
        <!-- <el-dropdown trigger="click" @command="changeValue">
          <span class="el-dropdown-link">
            {{ selectLanguage
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in switchLanguagesArr"
              :key="item"
              :command="index"
              >{{ item }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
      <div>
        <el-dropdown trigger="click">
          <div class="ml20 flex name_box">
            <el-avatar class="mr20" icon="el-icon-user-solid"></el-avatar>
            <div>{{ account }}</div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="exit">{{
              $t('exit')
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from '@/components/header/breadcrumb'
import storage from "@/utils/storage.js";
import { mapState, mapMutations } from "vuex";
import store from '@/store'
export default {
  data() {
    return {
      // switchLanguagesArr: [ "한국어", "中文" ],
      collapse: false,
    };
  },
  components:{breadcrumb},
  methods: {
    ...mapMutations(["saveUserInfo", "saveLanguage"]),
    exit() {
      this.$message.success({
        message: this.$t("signOutSuccess"),
      });
      storage.removeLocal("token");
      storage.removeLocal("userinfo");
      store.state.routes = null
      this.$router.push("/login");
    },
    // changeValue(index) {
    //   let obj = {};
    //   switch (index) {
    //     case 0:
    //       obj = {
    //         name: "한국어",
    //         txt: "ko",
    //         index: index,
    //       };
    //       break;
    //     case 1:
    //       obj = {
    //         name: "中文",
    //         txt: "zh",
    //         index: index,
    //       };
    //       break;
    //     // case 2:
    //     //   obj = {
    //     //     name: "English",
    //     //     txt: "en",
    //     //     index: index,
    //     //   };
    //     //   break;
    //   }
    //   this.saveLanguage(obj);
    //   location.reload();
    // },
    changeCollapse() {
      this.collapse = !this.collapse;
      this.$emit("changeCollapse");
    },
  },
  computed: {
    ...mapState(["adminUser", "selectLanguage"]),
    account() {
      const userinfo = storage.getLocal("userinfo");
      if (userinfo !== null && userinfo !== undefined) {
        return userinfo.username;
      }
      return 'UnKnow'
    },
  },
};
</script>
<style lang="scss" scoped>
.header{
    width:100%;
    height:100%;
    .el-button{
      border:none
    }
}
.name_box {
  margin-right: 20px;
  width: 110px;
  justify-content: space-between;
  cursor: pointer;
}
</style>
