<template>
  <el-aside
    class="leftSlide"
    style="width: auto"
    :class="collapse ? 'collapse' : 'notcollapse'"
  >
    <el-scrollbar style="height: 100%; width: auto">
      <div class="slideTop flexCC fc255 fs26">
        <i class="iconfont icon-kaoshi_moren2x mr10"></i>
        {{ !collapse ? "TongTong" : "" }}
      </div>
      <el-menu
        router
        unique-opened
        :default-active="$route.path"
        class="el-menu-vertical-demo"
        :collapse="collapse"
        background-color="#333744"
        text-color="#fff"
        active-text-color="#409EFF"
      >
        <el-menu-item index="/" v-if="role([1,2,3])">
          <i class="iconfont icon-shouye"></i>
          <span slot="title">{{ $t("tIndex") }}</span>
        </el-menu-item>
        <el-menu-item index="/member" v-if="role([1,2])">
          <i class="iconfont icon-user"></i>
          <span slot="title">{{ $t("tMember") }}</span>
        </el-menu-item>
        <el-submenu index="1" v-if="role([1,2])">
          <template slot="title">
            <i class="iconfont icon-wangye"></i>
            <span slot="title">{{ $t("tWebpage") }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/webpage/carousel">{{
              $t("tCarousel")
            }}</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/webpage/intro">{{
              $t("tIntro")
            }}</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/webpage/pass">{{
              $t("tPass")
            }}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2" v-if="role([1,3])">
          <template slot="title">
            <i class="iconfont icon-leimupinleifenleileibie2"></i>
            <span slot="title">{{ $t("tCategory") }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/category/examType">{{
              $t("tExamType")
            }}</el-menu-item>
            <el-menu-item index="/category/subject">{{
              $t("tSubject")
            }}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
         <el-menu-item index="/group" v-if="role([1,2])">
          <i class="iconfont icon-xiaozu"></i>
          <span slot="title">{{ $t("tGroup") }}</span>
        </el-menu-item>
        <el-submenu index="4" v-if="role([1,3])">
          <template slot="title">
            <i class="iconfont icon-kaoshitianchong"></i>
            <span slot="title">{{ $t("tExam") }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/exam/personal">{{
              $t("tExamPersonal")
            }}</el-menu-item>
            <!-- <el-menu-item index="/exam/group">{{
              $t("tExamGroup")
            }}</el-menu-item> -->
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="/order" v-if="role([1,2])">
          <i class="iconfont icon-dingdan"></i>
          <span slot="title">{{ $t("tOrder") }}</span>
        </el-menu-item>
        <el-submenu index="3">
          <template slot="title">
            <i class="iconfont icon-iconrx"></i>
            <span slot="title">{{ $t("tCustomerCenter") }}</span>
          </template>
          <el-menu-item-group v-if="role([1,2])">
            <el-menu-item index="/customerCenter/notice">{{
              $t("tNotice")
            }}</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group v-if="role([1,2])">
            <el-menu-item index="/customerCenter/faq">{{
              $t("tFAQ")
            }}</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group v-if="role([1,2])">
            <el-menu-item index="/customerCenter/qa">{{
              $t("tQA")
            }}</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group v-if="role([1,3])">
            <el-menu-item index="/customerCenter/wrong">{{
              $t("tWrong")
            }}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="/serviceTerm" v-if="role([1,2])">
          <i class="iconfont icon-fuwutiaokuan"></i>
          <span slot="title">{{ $t("tServiceTerm") }}</span>
        </el-menu-item>
        <el-menu-item index="/account" v-if="role([1])">
          <i class="iconfont icon-guanliyuan"></i>
          <span slot="title">{{ $t("tAccount") }}</span>
        </el-menu-item>
      </el-menu>
    </el-scrollbar>
  </el-aside>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "leftSlide",
  props: ["collapse"],
  data() {
    return {
      routerList: [],
      role2:[
        'login',
        'service_term',
        'notice',
        'faq',
        'qa',
        'group',
        'course',
        'banner',
        'service',
        'pass'
      ],
      role3:[
        'login',
        'position',
        'subject',
        'chapter',
        'section',
        'examination',
        'wrong',
        'userexam',
        'groupexam'
      ]
    };
  },
  methods: {
    role(arr){
      return arr.includes(this.adminUser.auth)
    }
  },
  computed: {
    ...mapState(["adminUser"]),
  },
  mounted() {
    this.routerList = this.$router.options.routes[0].children;
  },
  watch: {

  },
};
</script>
<style lang="scss">
.slideTop {
  height: 60px;
  i {
    font-size: 30px;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 250px;
  min-height: 400px;
  i {
    margin-right: 10px;
  }
}
.el-menu-vertical-demo {
  i {
    font-size: 20px;
  }
}
.collapse {
  .el-scrollbar__bar {
    background: #333744;
    display: none;
  }
  .el-scrollbar__wrap {
    overflow: hidden;
    width: 63.5px !important;
    background: #333744;
    transition: all 0.3s ease-in-out;
  }
  width: 63.5px !important;
  transition: all 0.3s ease-in-out;
}
.collapse:not(.show) {
  display: block;
}
.notcollapse {
  .el-scrollbar__bar {
    display: none;
    background: #333744;
  }
  .el-scrollbar__wrap {
    overflow: hidden;
    width: 250px !important;
    background: #333744;
    transition: all 0.3s ease-in-out;
  }
  width: 250px !important;
  transition: all 0.3s ease-in-out;
}
.leftSlide {
  height: 100%;
  background: #333744;
  position: relative;
  overflow: hidden;
  .el-menu {
    border: none;
  }
}

.el-menu-item-group__title {
  display: none;
}
</style>
